<template>
  <main>
    <div class="py-5 text-center" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else>
      <div v-if="redeemFormEnabled">
        <player :mode="mode" v-if="$store.state.currentCode || testMode"></player>
        
        <section class="container my-5" v-else>
          <div class="row">
            <div class="col-12">
              <div class="embed-responsive embed-responsive-16by9 bg-dark text-white">
                <div class="embed-responsive-item d-flex flex-column justify-content-center">
                  <div class="card align-self-center">
                    <div class="card-body text-dark">
                      <redeem-form></redeem-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-else>
        <player :mode="mode"></player>
      </div>

      <section class="container pb-4">
        <div class="row">
          <div class="col-12">
            <h1>Volvo Group Capital Markets Day 2020</h1>
            <h3>The Volvo Group invites institutional investors and financial analysts to a virtual Capital Markets Day on November 5.</h3>
            <p>Streamed live <strong>on November 5, 14:00–16:30 CET</strong> Volvo Group Executive Management will outline the Group’s strategic direction. The main focus of the event will be on the transformation journey into new sustainable technologies such as battery and hydrogen electric vehicles.</p>
          </div>
        </div>
        <div class="row" v-if="qnaEnabled">
          <div class="col-12 col-md-6">
            <p class="font-weight-bold">Ask your questions here:</p>

            <form name="questionForm" @submit.prevent="sendQuestion">
              <div class="form-group">
                <label for="name">Name</label>
                <input type="text" name="name" id="name" v-model="questionName" class="form-control">
              </div>

              <div class="form-group">
                <label for="question">Question</label>
                <textarea type="text" name="question" id="question" v-model="questionQuestion" class="form-control"></textarea>
              </div>

              <!-- <div class="form-group">
                <vue-recaptcha :sitekey="recaptchaSiteKey" @verify="recaptchaVerified"></vue-recaptcha>
              </div> -->

              <div class="text-success mb-2" v-if="questionThanks">
                {{ questionThanks }}
              </div>
              
              <button type="submit" name="send" id="send" class="btn btn-sm btn-success" :disabled="!this.questionFormValid">Send</button>
            </form>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  import { firebase } from "@/initializers/firebase"

  import Player from "@/components/player.vue"
  import RedeemForm from "@/components/redeem-form.vue"
  // import VueRecaptcha from "vue-recaptcha"

  export default {
    name: "home",
    // components: { Player, RedeemForm, VueRecaptcha },
    components: { Player, RedeemForm },
    data() {
      return {
        loading: true,
        recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        recaptchaValid: false,
        settings: null,
        sending: false,
        questionName: null,
        questionQuestion: null,
        questionThanks: null
      }
    },
    computed: {
      testMode() {
        return new URLSearchParams(window.location.search).get("wearejaws")
      },
      qnaEnabled() {
        if(this.settings) { return this.settings.qnaEnabled }
        return false
      },
      redeemFormEnabled() {
        if(this.settings) { return this.settings.redeemFormEnabled }
        return false
      },
      mode() {
        if(this.testMode) { return "test" }
        if(this.settings) { return this.settings.mode }
        return "offline"
      },
      questionFormValid() {
        return !this.sending && 
          (this.questionName && this.questionName.length > 0) &&
          (this.questionQuestion && this.questionQuestion.length > 0) &&
          (this.recaptchaValid)
      },
      ticketValid() {
        return ""
      }
    },
    methods: {
      recaptchaVerified() {
        this.recaptchaValid = true
      },
      async sendQuestion() {
        if(!this.questionFormValid) { return false }
        this.sending = true

        await firebase.firestore().collection(`questions`).add({
          name: this.questionName,
          question: this.questionQuestion,
          created: firebase.firestore.FieldValue.serverTimestamp()
        })

        this.questionQuestion = null
        this.questionThanks = "Thank you for your question."
        this.sending = false

        setTimeout(() => {
          this.questionThanks = null
        }, 2000)
      }
    },
    async mounted() {
      await this.$bind("settings", firebase.firestore().collection(`application`).doc(`settings`))

      this.loading = false
    }
  }
</script>

<style lang="scss" scoped>
  
</style>