<template>
  <header class="positon-sticky bg-white d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <a href="/"><img src="../assets/images/volvo-group-logo.png" class="logo d-inline-block" alt=""></a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  export default {
    name: "site-header"
  }
</script>

<style lang="scss" scoped>
  header { height: 100px; }
  .logo { height: 100px; }
</style>