<template>
  <div id="app">
    <site-header></site-header>

    <router-view></router-view>
  </div>
</template>

<script>
  import SiteHeader from "@/components/site-header.vue"

  export default {
    name: "app",
    components: { SiteHeader },
    data() {
      return {
        logo: require("./assets/images/bg.jpg")
      }
    },
    computed: {
      origin() {
        let url = new URL(window.location)
        return url.origin
      },
      socialImage() {
        return `${this.origin}${this.logo}?cache=1`
      }
    },
    metaInfo() {
      return {
        meta: [
          { name: "twitter:card", content: "summary" },
          { name: "twitter:image", content: this.socialImage },
          { name: "twitter:title", content: "Capital Markets Day | Volvo Group" },
          { name: "twitter:description", content: "The Volvo Group invites institutional investors and financial analysts to a virtual Capital Markets Day on November 5." },
          { property: "og:type", content: "website" },
          { property: "og:title", content: "Capital Markets Day | Volvo Group" },
          { property: "og:description", content: "The Volvo Group invites institutional investors and financial analysts to a virtual Capital Markets Day on November 5." },
          { property: "og:url", content: this.origin },
          { property: "og:image", content:  this.socialImage }
        ]
      }
    }
  }
</script>
