<template>
  <div id="redeem">
    <form name="redeem" @submit.prevent="redeem">
      <div class="form-group mb-0">
        <label for="codeString"><strong>Code</strong></label>
        
        <div class="input-group">
          <input type="text" name="codeString" id="codeString" class="form-control" v-model="codeString" placeholder="Enter code" aria-label="Ange biljettnummer" aria-describedby="redeem-button">
          <div class="input-group-append">
            <button type="submit" class="btn btn-success" id="redeem-button" :disabled="loading || !codeString">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
              Join!
            </button>
          </div>
        </div>
      </div>

      <div class="text-danger mt-2" v-if="error">
        {{ error }}
      </div>
    </form>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase"

  export default {
    name: "redeem-form",
    data() {
      return {
        loading: false,
        codeString: null,
        error: null
      }
    },
    methods: {
      async redeem() {
        this.loading = true

        let codeRef = await firebase.firestore().collection(`codes`).doc(this.codeString)
        let ticket = await codeRef.get()

        if(ticket.exists) {
          await codeRef.update({ currentUser: this.$store.state.currentUser.uid, used: true })
          this.$store.commit("setCurrentCode", { codeString: ticket.id })

          this.loading = false
          this.codeString = null
        } else {
          this.error = "Invalid code"
          this.loading = false
        }
      }
    }
  }
</script>