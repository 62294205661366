<template>
  <div class="player my-5" :key="mode">
    <div class="container">
      <section class="row">
        <div class="col" v-if="mode == 'live' || mode == 'test'">
          <div class="embed-responsive embed-responsive-16by9 bg-dark text-white">
            <div class="embed-responsive-item">
              <jwplayer></jwplayer>
            </div>
          </div>
        </div>

        <div class="col" v-else-if="mode == 'offline'">
          <div class="embed-responsive embed-responsive-16by9 bg-dark text-white">
            <div class="embed-responsive-item d-flex flex-column align-items-center justify-content-center">
              <div class="h4 m-0">We're offline</div>
            </div>
          </div>
        </div>

        <div class="col" v-else-if="mode == 'pre'">
          <div class="embed-responsive embed-responsive-16by9 bg-dark text-white background-image">
            <div class="embed-responsive-item d-flex flex-column align-items-center justify-content-center">
              <vue-countdown :time="time" v-if="time">
                <template slot-scope="props">
                  <div class="h4 m-0 p-3 bg-dark-trans">
                    {{ props.days }} {{ props.days == 1 ? "day" : "days" }},
                    {{ props.hours }} {{ props.hours == 1 ? "hour" : "hours" }},
                    {{ props.minutes }} {{ props.hours == 1 ? "minute" : "minutes" }},
                    {{ props.seconds }} {{ props.hours == 1 ? "second" : "seconds" }}
                  </div>
                </template>
              </vue-countdown>
            </div>
          </div>
        </div>

        <div class="col" v-else-if="mode == 'post'">
          <div class="embed-responsive embed-responsive-16by9 bg-dark text-white">
            <div class="embed-responsive-item d-flex flex-column align-items-center justify-content-center">
              <div class="h4 m-0">Thanks for watching!</div>
            </div>
          </div>
        </div>

        <div class="col" v-else-if="mode == 'ondemand'">
          <div class="embed-responsive embed-responsive-16by9 bg-dark text-white">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/VpSx5HzHueQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase"

  import VueCountdown from "@chenfengyuan/vue-countdown"
  import Jwplayer from "@/components/jwplayer"

  export default {
    name: "player",
    components: { VueCountdown, Jwplayer },
    props: {
      mode: {
        type: String,
        default: "offline"
      }
    },
    data() {
      return {
        loading: true,
        code: null,
        eventStart: "2020-11-05T14:00:00",
        eventTimezone: "Europe/Stockholm",
        time: null,
      }
    },
    computed: {
      eventStartObject() {
        return this.$DateTime.fromISO(this.eventStart, { zone: this.eventTimezone })
      }
    },
    methods: {
      closePlayer() {
        this.$store.commit("setCurrentCode", { codeString: null })
      }
    },
    async mounted() {
      if(this.$store.state.currentCode) {
        await this.$bind("code", firebase.firestore().collection(`codes`).doc(this.$store.state.currentCode))
      }

      let now = this.$DateTime.local().setZone(this.eventTimezone)
      let millis = this.eventStartObject.diff(now).toObject()

      this.time = millis.milliseconds

      this.loading = false
    }
  }
</script>

<style lang="scss">
  .background-image {
    background-image: url(../assets/images/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-dark-trans {
    background-color: rgba(#222222, .7);
  }
</style>